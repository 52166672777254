/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

// Custom Link Styling located at bottom of doc

// 1. Page Element Styles
.linkElement{
  h4{
    font-family: $fontOne;
    font-size: rem(14);
    font-weight: 400;
    a{
      background-color: $link-color;
      // box-shadow: 0 -3px $link-color-darken-10 inset;
      display: block;
      color: #fff;
      padding: 10px 20px 10px 10px;
      margin-bottom: 0px;
      line-height: 1.3;
      text-decoration: none;
      transition: background-color .2s;
      &:hover{
        background-color: $link-color-darken-10;
      }
    }
  }
  .goToLink,
  .emailLink{
    &:before{
      display:none;
    }
    &:after{
      color: #fff;
      content: "\f061"; // Right Arrow
      font-family: FontAwesome;
      display:inline-block;
      line-height: .8em;
      margin:0 0 0 6px;
      padding:0;
      position: relative;
      transition: color .2s;
    }
    &:hover:after {
      color: #fff;
    }
  }
  .emailLink {
    &:after{
      content: "\f003"; // envelope-o
    }
  }
  .text{
    font-size:rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
}

// 2. Admin Panel Styles
.admin .linkElement{
  h4{
    font-family:$fontOne;
    font-size:rem(18);
    font-weight:700;
    background:transparent;
    padding:10px 0 5px 0;
    &:hover{
      background:transparent;
    }
    a{
      display: inline;
      color:$link-color;
      padding: 0;
      margin-bottom: 0px;
      background:transparent;
      text-decoration:none;
      opacity: 1;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .goToLink{
    &:before,
    &:after{
      display:none;
    }
  }
  .text{
    font-size:rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }
}


// ---------------Custom Link Styling------------------- //


.customLogoLink {
  .pageElement {
    height: 75px;
    background: $custom-link-color;
    overflow: hidden;

    img {
      width: auto;
      height: 75px;
      background-color: $highlight-color;
      float: left;
      z-index: 1;
      position: relative;
      // margin: 13px 20px 13px 13px;
      padding: 5px 15px;
      margin-right: 10px;
    }
    &.linkElement h4 a {
      height: 100%;
      padding: 2% 0 0 0;
      // line-height: 60px;
      font-size: 24px;
      font-weight: 400;
      box-shadow: none;
      background-color: $custom-link-color;
      &:after {display: none;}
    }

     &.linkElement {
      .text {
        height: 100%;
        font-size: 14px;
        font-family: $fontOne;
        font-style: normal;
        font-weight: 300;
        padding: 0;
        color: #ffffff;
        background-color: $custom-link-color;
      }
    }
  }
}
