.snFooterContainer {
  max-width: none;
  background-color: $highlight-color;
}

#extendedFooter {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  .globalFooter {
    padding-top: 50px;

    :first-child:not(.footerSection) {
      // width: 150px;
    }
  }

  .footerLogo {
    width: 150px;
    float: left;
    margin-right: 50px;
    border-right: 1px solid #0e2240;
    .heroPhotoElement {
      width: 120px;
      img {border: none;}
    }
  }

  .footerSection {
    float: left;
    width: 20%;
    color: #fff;

    h3 {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
    }
    h4 {
      color: $footer-links;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      a {color: $footer-links;}
    }

    &.noHeader { margin-top: 32px; }
  }
}
