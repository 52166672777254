html.has-mobile-nav {

  //mobile navigation present resets
  #siteContainer {padding-top: 10px;}

  #panelTwo {overflow: hidden;}

  #yieldContent {overflow: hidden;}

  #accountNav > #logout {display: none;}

  #accountNav {
    background-color: $highlight-color;
    li {
      &#login {
        display: none !important;
      }
    }
  }
  // ------------------------------------
  //centering the affiliate logo when mobile nav is present
  // ------------------------------------
  #sn-site-logo {
    display: block;
    position: relative;
    margin: 0 auto;
    left: 0;
    width: 60px;
    height: 60px;
    z-index: 0;
  }

  #topNav {


    .affiliateButton {
      position: absolute;
      width: 25%;
      cursor: pointer;
      margin-left: 10px;
      @media only screen and (max-width: 600px){
        h4 {padding-top: 10px !important;}
      }
      h4 {
        color: #fff;
        font-size: 12px;
        font-family: $fontTwo;
        font-weight: 300;
        white-space: normal;
        padding-top: 15px;
        float: left;
      }
      &:after {
        font-family: "FontAwesome";
        content: "\f054";
        font-size: 12px;
        position: absolute;
        color: #fff;
        margin-top: 15px;
        transition: all 100ms linear;
        // margin-left: 10px;
      }

      &.closed {
        &:after {
          // content: "\f078";
          transform: rotate(90deg);
          // margin-left: -3px;
          transition: all 100ms linear;
        }
      }
    }
  }

  // Mobile Network Bar Styling
  .layoutContainer {
    &.networkBar {
      width: 100%;
      height: 0;
      transition: height 200ms linear;
      overflow: hidden;
      z-index: 100;
      position: absolute;
      background-color: #fff;
    }
  }




  .layoutContainer {
    &.networkBar > div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      padding: 0 10px 0 10px;
      margin: 0;
      .pageEl {
        width: 50%;
        &.scnLogo {
          width: 100%;
          .heroPhotoElement {
            width: 50%;
            float: none;
            margin: 10px auto 20px auto;
          }
        }
        .heroPhotoElement {
          width: 50%;
          float: none;
          margin: 10px auto;
        }
      }
    }
  }

  .seasonTicketBar {
    width: 121%;
    margin-left: -11%;
  }

  .supportKC {
    .heroPhotoElement {
      width: 121%;
      margin-left: -11%;
      background-image: url("https://assets.ngin.com.s3.amazonaws.com/site_files/13250/_site/images/supportMobile.png");
      height: 100%;
      a {

    }
      // img {display: hidden;}
    }
  }

  // mobile full width news slideshow
  .newsSlideShow {
    width: 121%;
    margin-left: -11%;
    margin-bottom: 5px;
  }


    .mapOverlay:last-child {
      // .column:last-child {
        padding: 0;
      // }
    }

  // mobile full width youtube element
  .mirroredYouTube {
    width: 100%;
  }

// Custom Call to action buttons red with blue background
@media only screen and (max-width: 767px) {
  .customCtaBar {
    padding: 10px 0 0 0;
    .pageEl {
      &.customCTA {
        .sn-call-to-action {
          height: 120px;
          overflow: hidden;
          p {
            font-weight: 500;
            line-height: 0.5;
          }
        }
        .sn-call-to-action-overlay {
          padding: 5px;
        }
      }
    }
  }
}

//Custom logo links
.customLogoLinkElement {
  padding: 0;
  .column {
    padding: 0;
    .customLogoLink {
      .pageElement {
        &.linkElement {
          h4 a {
            font-size: 16px;
            padding-top: 18px;
          }
          img { padding: 10px; }
        }
      }
    }
  }
}

// Affiliate Camps and Gear mirrored content
.affiliateCampsGear {
  padding: 0;
  .column {
    padding: 0;
    .skcContent {
      .heroPhotoElement {
        height: 100%;
      }
    }
  }
}

// Mobile full bleed section headers
.sectionHeaderLeft {
  width: 121%;
  margin-left: -11%;
  p { padding-left: 15%; }
}

.sectionHeaderRight {
  width: 121%;
  margin-left: -11%;
  p { padding-right: 15%; }
}

//affiliate full bleed section header
.sponsorBar {
  .column {
    display: flex !important;
  }
  .sponsorLogo {
  margin: 11px;
    .heroPhotoElement {
      width: 80px;
    }
  }
  .sectionHeader {
    p {
      width: 121%;
      margin-left: -11%;
      margin-bottom: 10px;
      padding-left: 60px;
    }
  }
}

#extendedFooter {
  .globalFooter {
    margin-bottom: 10px;
    .footerLogo {
      width: 50%;
      margin-right: 0;
      .heroPhotoElement {
        float: none;
        margin: 0 auto;
      }
    }
    .sportingKCLogo {
      width: 50%;
      float: right;
      // margin-right: 1%;
      .heroPhotoElement {
        width: 120px;
        margin: 0 auto;
        float: none;
      }
    }
  }
}

#siteFooter > ul {
  text-align: center;
  #poweredByNGIN {
    width: 100%;
    float: none;
    a {
      margin: 0 auto;
      float: none;
    }
  }
}

.footerSection { display: none; }
}

html.has-mobile-nav {
  &.page-manager-visible {
    .layoutContainer {
      &.networkBar {
        // margin-right: -$page-manager-width;
        width: auto;
      }
    }
  }
}


// Custom Mobile Breakpoints
@media only screen and (min-width: 300px) and (max-width: 600px){
  html.has-mobile-nav {
    .seasonTicketBar {
      h3 {
        font-size: 19px;
        margin-bottom: -5px;
      }
    }
  }
}

@media only screen and (min-width: 450px) {
  html.has-mobile-nav {
    .supportKC {
      .heroPhotoElement {
          width: 115%;
          margin-left: -8%;
      }
    }
  }
}


@media only screen and (min-width: 657px) {

  html.has-mobile-nav {
    .supportKC {
      .heroPhotoElement {
          width: 111%;
          margin-left: -6%;
      }
    }
    .waitingList {
      .heroPhotoElement {
          width: 111%;
          margin-left: -6%;
      }
    }
  }
}

//general styling until columns break
@media only screen and (max-width: 768px){
  html.has-mobile-nav {
    .sectionHeaderRight {
      p {
        text-align: center !important;
        padding: 0;
        margin: 0;
      }
    }
    .sectionHeaderLeft {
      p {
        text-align: center;
        padding: 0;
        margin: 0;
      }
    }
    .sectionHeader {
      p {
        text-align: center;
        padding: 0 !important;
        margin: 0;
      }
    }
  }
}

// Page breaks back into two columns
@media only screen and (min-width: 768px) {
  html.has-mobile-nav {
    .mirroredYouTube {
      width: auto;
      margin: 0;
    }

    .customLogoLinkElement {
      .customLogoLink {
        padding: 0 5px;
      }
    }

    .skcContent {
       &.camps {
        img { margin-top: -14px;}
      }
    }
  }
}

//mobile navigation goes away at 1024 px
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  html.has-mobile-nav {
    .customCtaBar {
      .pageEl {
        &.customCTA {
          .sn-call-to-action {
            h4 {
              font-size: 24px;
            }
            p {
              font-size: 24px;
            }
          }
        }
      }
    }
    .affiliateCampsGear {
      .column {
        padding: 5px;
      }
    }
    .newsSlideShow {
      width: 100%;
      margin: inherit;
    }
    .sectionHeaderRight {
      width: 110%;
      margin-left: 0;
    }
    .sectionHeaderLeft {
      width: 110%;
      margin-left: -10%;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .affiliateButton {
    &:after {margin-left: 10px;}
  }
}

@media only screen and (min-width: 660px) and (max-width: 768px){
  html.has-mobile-nav {
    .newsSlideShow {
      width: 111%;
      margin-left: -6%;
    }
  }
}

@media screen and (max-width: 375px){
  html.has-mobile-nav {
    #topNav {
      .affiliateButton {
        width: 30%;
      }
    }
  }
}

// Mobile nav goes away at 1024 and max width is 1200
// adjusting top nav to be responsive between these values
@media only screen and (min-width: 1024px) and (max-width: 1240px) {
  #topNav {
    .theme-search-bar {
      #extendHeader {
        .globalSiteHeader {
          .sportingKCLogo {
            margin-right: 12%;
          }
        }
      }
    }
  }
}

html.has-mobile-nav {
  .countdownElement {
    abbr { text-align: left; }
  }
}

@media only screen and (max-width: 540px) {
  .countdownElement {
    background-size: 140% !important;
    background-repeat: no-repeat !important;
  }
}
