// media slider
.pageElement.mediaSlider > h2,
.pageElement.mediaSlider > p {
    display: none;
}
.mirroredYouTube .codeElement {
    position: relative;
    padding-bottom: 52%;
    padding-top: 25px;
    height: 0;
}
.mirroredYouTube .codeElement iframe {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}
// Misc element styling
.sectionHeaderLeft {
  font-family: $fontOne;
  color: #ffffff;
  text-transform: uppercase;
  p {
    font-size: 24px;
    line-height: 40px;
    height: 40px;
    background-color: $highlight-color;
    padding-left: 43px;
    margin-left: -42px;
  }
}

body{
  &.user_mode{
    [data-nav-level="2"]{
      a[title="click to go to 'Network bar'"]{
        display: none !important;
      }
    }
  }
}

// An active banner must be present for the email templates to work.
//This hides the active banner.
.site-banner-wrapper {
  display: none;
}

.sectionHeaderRight {
  font-family: $fontOne;
  color: #ffffff;
  text-transform: uppercase;
  p {
    font-size: 24px;
    line-height: 40px;
    height: 40px;
    background-color: $highlight-color;
    padding-right: 34px;
    margin-right: -30px;
  }
}
.column-3 {
  .sectionHeaderRight {
    margin-left: 30px;
  }
}

//skc mirrored content
.seasonTicketBar {
  height: 40px;
  width: 100%;
  background: $accent-color;
  text-transform: uppercase;
  & a:hover{
    text-decoration: none;
  }
  .pageElement {
    &.textBlockElement {
      h3 {
        color: white;
        text-align: center !important;
        line-height: 40px;
      }
    }
  }
}

.skcContent {
  &.camps {
    img {
      margin-top: -23px;
    }
  }
  .heroPhotoElement {
    height: 200px;
    overflow: hidden;
    img {
      border: none;
    }
  }
}

//Mirrored affiliate styling
.layoutContainer {
  &.sponsorBar > div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.layoutContainer {
  .sectionHeader {
    flex: 0 1 100%;
    p {
      color: #ffffff;
      text-transform: uppercase;
      background-color: $highlight-color;
      width: 35%;
      padding-left: 40px;
      margin-left: -42px;
      margin-bottom: 20px;
    }
  }
}

.sponsorLogo {
  width: 100px;
  height: 100%;
  flex-basis: 15%;
  .heroPhotoElement {
    width: 90px;
    height: auto;
    img {border: none;}
  }
}

html.has-mobile-nav {
  .sponsorLogo {
    flex-basis: auto;
  }
}


// Hiding the affiliate button if no mobile nav is present
// Will use mobilePresent to show and hide button with JS
#topNav {
  .affiliateButton {
    &.mobileNotPresent {
      display: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  #topNav {
    .affiliateButton {
      &.mobileNotPresent {
        display: block;
      }
    }
  }
}

// Network bar Styling
html:not(.has-mobile-nav) {
  .layoutContainer {
    &.networkBar > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1200px;
      // padding-left: 0;
      // position: absolute;
      // margin-left: -35px;
      // float: right;
    }
    &.networkBar {
      max-width: 1200px;
      margin: 10px auto;
      padding-left: 0;
      .scnLogo {
        .heroPhotoElement {
          width: 154px;
        }
      }
      .affiliateLogo {
        filter: grayscale(100%);
        transition: filter 200ms linear;
        &:hover {
          filter: none;
          transition: filter 200ms linear;
        }
      }
      .heroPhotoElement {
        width: 40px;
        img {
          border: none;
          transform: translateY(8px);
        }
        }
        .column {
          position: static;
          z-index: 500;
          transition: transform 200ms linear,
                      background-color 200ms linear;
        }
      }
  }
}


.nav-fixed {
  .layoutContainer {
    &.networkBar {
      .column {
        // transform: translateY(51px);
        background-color: $custom-link-color !important;
      }
    }
  }
}

// Ditching the filter if the network bar is in the dropdown menu
html.has-mobile-nav {
  .layoutContainer {
    &.networkBar {
      .affiliateLogo {
        filter: none;
      }
      .scnLogo {
        filter: none;
      }
    }
  }
}

// Style the title that appears on hover
// .has-main-nav .affiliateLogo a[title]:hover:after {
//     content: attr(title);
//     padding: 4px 8px;
//     color: #fff;
//     position: absolute;
//     left: -35px;
//     top:110%;
//     white-space: nowrap;
//     background-color: rgba(0,0,0,.7);
//     font-size: 10px;
//     width: 100px;
//     text-align: center;
// }

// Mirrored Twitter Element
.twitterMirror {
  .codeElement {
    iframe {
      border: 1px solid #eee !important;
      border-radius: 2px;
    }
  }
}

//style guide custom styling
#assets {
  #siteContainer {
    // background-color: #ababab;
  }
  .affiliateLogoAsset {
    width: 25%;
    img {border: none;}
  }
  .sportingKCLogo {
    width: 150px;
  }
  .layoutContainerMirrored {
    .networkBar {
      border: none;
    }
  }

  .footerLogo {
    width: 150px;
    float: left;
    margin-right: 50px;
    border-right: 1px solid #0e2240;
    .heroPhotoElement {
      width: 120px;
      img {border: none;}
    }
  }

  .footerSection {
    float: left;
    width: 20%;
    color: #fff;

    h3 {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
    }
    h4 {
      color: $footer-links;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
    }

    &.noHeader { margin-top: 32px; }
  }
}

//custom site guide styling
#siteGuide {
  .affiliateLogoAsset {
    width: 150px;
    margin: 0 auto 10px;
  }
  .guideText {
    margin-top: 40px;
  }
}

//style guide custom styling
#styleGuide {
  .sn-social-media-list {
    background: $accent-color-alternate;
    padding: 10px;
  }
}

// Edit Mode Custom Styling
body.edit_mode {
	.networkBar {
			&:before {
				content: "";
				height: 0;
				// transition: all .4s;
			}
			&:hover {
				&:before {
					content: "Please Edit in Assets Page";
					color: #ffffff;
					font-size: 60px;
					text-align: center;
					// line-height: 190px;
          margin-left: -14px;
          margin-top: -10px;
          text-indent: -25%;
					display: block;
					height: 250px;
					width: 1200px;
					background-color: $accent-color;
					opacity: .8;
					position: fixed;
					z-index: 10;
					transition: all .4s;
				}
			}
      .columnBounds {
      	border: none;
        display: flex;
        width: 100%;
        justify-content: space-between;
      	margin: 5px 0;
      }
      .pageElementAdmin {
      	clear: none;
      	.elementBar {
      		display: none;
      	}
      }
   }
   #extendHeader {
     .globalSiteHeader {
       .columnBounds {
         border: none;
         margin: 5px 0;
       }
       .pageElementAdmin {
         clear: none;
         .elementBar {
           display: none;
         }
       }
     }
   }
	.snFooterContainer {
		#extendedFooter {
			&:before {
				content: "";
				height: 0;
				width: 100%;
				transition: all .4s;
			}
			&:hover {
				&:before {
					content: "Please Edit in Assets Page";
					color: #ffffff;
					font-size: 60px;
					text-align: center;
					line-height: 230px;
					display: block;
					height: 230px;
					width: 100%;
					background-color: $accent-color;
					opacity: .8;
					position: absolute;
          left: 0;
					z-index: 10;
					transition: all .4s;
				}
			}


	.globalFooter {
		.columnBounds {
			border: none;
			margin: 5px 0;
		}
		.pageElementAdmin {
			clear: none;
			.elementBar {
				display: none;
			}
		}
	}
}
}

  .pageEl {
    &.sponsorLogo {
      width: 90px;
    }
  }
}

// Affiliate bar new asset fix

html:not(.has-mobile-nav) {
  .layoutContainer.networkBar {
    .scnLogo .heroPhotoElement img {
      max-height: 40px;
      width: auto;
    }
  }
}

// Additional padding to keep topNav from overlapping onto page content when not logged in

// body:not(.logged_in) {
//   .yieldPageContent {
//     padding-top: 50px;
//   }
// }
